import ukraineImg from "../assets/images/services__page_img/ukraine.png";
import internationalImg from "../assets/images/services__page_img/international.png";
import adrImg from "../assets/images/services__page_img/adr.png";
import collectiveImg from "../assets/images/services__page_img/collective.png";
import containerImg from "../assets/images/services__page_img/container.png";
import containerStorageImg from "../assets/images/services__page_img/container-storage.png";

export const stages_of_work = [
  {
    id: 1,
    link: "/ukraine-cargo",
    img: ukraineImg,
    title: `ПЕРЕВЕЗЕННЯ \n ВАНТАЖІВ \n ПО \n ТЕРИТОРІЇ \n УКРАЇНИ`,
    desc: `ІСТ-Захід - це провідна транспортно-експедиційна компанія, яка спеціалізується на перевезенні різноманітних вантажів по всій території України. Наша мета - забезпечити ефективні та індивідуалізовані рішення для безпечного переміщення вашого вантажу. Незалежно від обсягу чи характеру вантажів, ми гарантуємо надійність та оперативність у доставці.`,
    info: `
    Доставляємо вантажі будь-якого розміру та типу по всій території України.

    Пропонуємо ефективні та індивідуальні рішення для безпечного доставлення вантажу. Незалежно від обсягу чи типу вантажу — гарантуємо надійність та швидкість транспортування.
    
    З моменту заснування ІСТ-Україна у 1992 році ми ефективно розвиваємо напрямок автомобільних перевезень вантажів територією нашої країни. Сьогодні ми є ключовим гравцем на цьому ринку. Наш досвід дозволяє справитися із завданнями будь-якої складності. 
    
    Послуги автомобільних перевезень вантажів від ІСТ-Захід — це комплекс усіх можливих логістичних рішень на ринку України. Співпраця з нами — гарантія безпечної та своєчасної доставки вантажів українськими дорогами. 

    
    Наші підрядники — це перевізники з бездоганною репутацією. Укладаємо угоди лише з тими, хто дотримується двох важливих принципів транспортування вантажів: постійний технічний контроль за станом рухомого майна та страхування відповідальності перевізника. 
    
    Підбираємо транспортні засоби для всіх видів автомобільних перевезень вантажів:

          - автопоїзди з тентованими напівпричепами;
          - автопоїзди з напівпричепами типу "Jumbo" і "Mega";
          - суцільнометалеві та ізотермічні трейлери;
          - рефрижератори всіх типів;
          - автопоїзди підвищеного об’єму;
          - малотоннажні автомобілі та мікроавтобуси;
          - пересувний склад для перевезення спеціальних і небезпечних вантажів усіх класів;
          - низькорамні платформи для транспортування негабаритних вантажів.


    Попри виклики, що постають перед логістичними компаніями в Україні, ми не зупиняємося. ІСТ-Захід — це ваш надійний партнер, який продовжує перевозити вантажі до будь-яких місць, де це можливо. Змінюються зовнішні умови, тому ми проявляємо гнучкість у вирішенні найскладніших завдань. Завжди знаходимо рішення, щоб забезпечити надійну та ефективну доставку ваших вантажів.
    Наша невтомна команда готова до нових задач. Надійність логістичних послуг в Україні перевірена часом. Звертайтесь до нас, щоб ваші вантажі прибувати вчасно у пункт призначення. Ми добре знаємо свою справу!
    
   `,
    stages: [
      {
        title: `Індивідуальний підхід`,
        desc: `Індивідуальний підхід до потреб кожного клієнта. При плануванні перевезень беремо до уваги специфіку вантажу, особливості маршрутів, вимоги клієнта та унікальні особливості доставки.`,
      },
      {
        title: `Контроль`,
        desc: `Технічний контроль перевізників, який проводимо постійно для гарантії безпечних та надійних перевезень
            `,
      },
      {
        title: `Масштабованість`,
        desc: `Мережа офісів по Україні. Розгалужена структура офісів дозволяє ефективно організовувати транспортування вантажу в різні куточки країни.
            `,
      },
      {
        title: `Досвід працівників`,
        desc: `Менеджери, які працюють у наших офісах, здатні розв’язати будь-які логістичні задачі клієнта. 
            `,
      },
      {
        title: `Якісне обслуговування`,
        desc: `Комунікуємо з клієнтом так, щоб заощадити ваш час та  знайти ефективне логістичне рішення.

            `,
      },
      {
        title: `Відповідальність`,
        desc: `Матеріальна відповідальність за дотримання термінів та збереження вантажу зафіксована угодами.


            `,
      },
    ],
  },
  {
    id: 2,
    link: "/international-cargo",
    title: `МІЖНАРОДНІ \n ВАНТАЖНІ \n ПЕРЕВЕЗЕННЯ`,
    img: internationalImg,
    desc: `ІСТ-Захід - ваш надійний партнер у сфері міжнародних вантажних перевезень. Ми забезпечуємо комплексні та надійні рішення для перевезення вантажів міжнародними маршрутами. Наш досвід та професійний підхід гарантує безпеку та швидкість доставки вашого вантажу в будь-яку точку світу.`,
    info: `
    Доставляємо вантажі в будь-яку точку світу.

ІСТ-Захід — надійний партнер у міжнародних вантажних перевезеннях. Пропонуємо найефективніші рішення для транспортування вантажів по всьому світу. Швидко та безпечно доставимо ваш вантаж за вказаною адресою.

Автомобільні перевезення вантажів країнами Європи та Азії — це один з основних напрямів роботи нашої компанії. Логістичні маршрути, які відпрацьовувалися роками та досвід персоналу дають нам змогу утримувати лідерські позиції на цьому ринку. Завдяки вмінню швидко адаптовуватися до змін та знаходити найкращі рішення, ми забезпечуємо ефективні та надійні перевезення для наших клієнтів навіть у часи постійних змін.

Організовуємо перевезення вантажів країнами Західної, Центральної та Східної Європи, Скандинавії та Балтії, транспортуємо вантажі шляхами Азії, доставляємо до Індії, Китаю та інших віддалених країн. Завдяки нашим партнерам — найбільшим та найнадійнішим автоперевізникам Європи, знаємо як розв’язати найскладнішу логістичну задачу.  

При виборі перевізника на міжнародні маршрути виставляємо три важливі пріоритети: 

- постійний технічний контроль за станом рухомого майна;
- обов’язкове страхування матеріальної відповідальності;
- практичний досвід водіїв на обраних маршрутах.

Це запорука нашої впевненості у надійності транспортування та збереженні ваших вантажів. 

Вибудовуючи систему якості послуг міжнародних перевезень акцентувалися на важливих для наших клієнтів моментах:

- високі вимоги до рівня матеріально-технічної бази перевезень;
- постійне навчання та вдосконалення професійних навичок персоналу на всіх рівнях;
- індивідуальний підхід до потреб та вимог кожного замовника;
- система постійного контролю за місцем знаходження автомобіля;
- оперативне реагування на непередбачувані ситуації в процесі доставки.

Менеджери ІСТ-Захід контролюють процес транспортування вантажу клієнта та знаходяться на постійному зв’язку як з водієм, так і з замовником перевезення. Для ще більшої зручності розробили систему, яка дозволяє клієнту бачити рух вантажу в режимі реального часу.

В договорах, які ми укладаємо з клієнтами, передбачена наша матеріальна відповідальність за дотримання термінів доставки та збереження вантажу. Ще один важливий момент, за який передбачена фінансова відповідальність — збереження конфіденційної інформації. З ІСТ-Захід ваш вантаж у повній безпеці, в будь-якій точці Європи та Азії.

Організовуємо перевезення вантажів усіма видами автомобільних транспортних засобів, обираючи найефективніші для ваших логістичних задач:

- автопоїзди з тентованими напівпричепами;
- автопоїзди з напівпричепами типу "Jumbo" і "Mega";
- суцільнометалеві та ізотермічні трейлери;
- рефрижератори всіх типів;
- автопоїзди підвищеного об’єму;
- малотоннажні автомобілі та мікроавтобуси;
- пересувний склад для перевезення спеціальних і небезпечних вантажів усіх класів;
- низькорамні платформи для транспортування негабаритних вантажів.

ІСТ-Захід — знаємо, як доставити автомобілем будь-які вантажі, від делікатного медичного обладнання до великогабаритної будівельної техніки, у будь-яке місце, від Атлантики до Тихого океану, у визначений термін.
`,
    stages: [
      {
        title: `Консультація та планування`,
        desc: `Наші логістичні консультанти визначать оптимальний маршрут та вид транспорту, забезпечуючи ефективність та швидкість перевезення.`,
      },
      {
        title: `Документація та дозволи`,
        desc: `Ми здійснюємо повний комплекс робіт з оформлення документації та отримання всіх необхідних дозволів для міжнародних перевезень.`,
      },
      {
        title: `Моніторинг та звітність`,
        desc: `Ми забезпечуємо постійний моніторинг руху вантажу та надаємо звітність клієнтам, щоб вони могли в реальному часі слідкувати за статусом перевезення.`,
      },
    ],
  },
  {
    id: 3,
    link: "/adr-cargo",
    title: `ПЕРЕВЕЗЕННЯ \n НЕГАБАРИТНИХ \n ТА \n НЕБЕЗПЕЧНИХ \n ADR \n ВАНТАЖІВ`,
    img: adrImg,
    desc: `Наша компанія має значний досвід у перевезенні негабаритних та небезпечних (ADR) вантажів, що забезпечує відповідність вимогам.`,
    info: `
    Досвід перевезень негабаритних та небезпечних (ADR) вантажів.
    
ІСТ-Захід — надійний партнер у міжнародних вантажних перевезеннях. Пропонуємо найефективніші рішення для транспортування вантажів по всьому світу. Знаємо, як швидко та безпечно доставити ваш вантаж за вказаною адресою.

Якісна організація перевезення негабаритних та небезпечних вантажів з дотриманням вимог ADR потребує відповідного досвіду. Це справа, яку будь-кому не довіриш. Висококваліфіковані менеджери ІСТ-Захід знають, як все зробити правильно. 

Європейська угода про міжнародне перевезення небезпечних (ADR) вантажів дорогами  містить ряд обов’язкових вимог щодо пакування, маркування, етикетування, оформлення супровідної документації, кваліфікації водіїв та оснащення транспортних засобів. До того ж перевезення небезпечних вантажів регулюються національним законодавством різних країн. Тому вибір надійної логістичної компанії дуже важливий для таких перевезень.

Порушення правил перевезень може призвести до серйозної шкоди довкіллю та здоров’ю людей, відповідно, дотримання норм є обов’язковим. Санкції за недотримання вимог ADR при транспортуванні не обмежуються лише штрафами, а можуть призвести до вилучення вантажу. 


Організовуємо перевезення спецтранспортом, який призначений для різних типів негабаритних, нестандартних та надважких вантажів. Для вирішення цих складних логістичних задач використовуємо:

- телескопічні платформи TIEFBET із вантажною платформою, що демонтується;
- подовжені платформи SEMITRAILER, що висуваються на 3 метри; 
- подвоєні платформи з гідравлічно керованими осями. 

Уважно вивчаємо питання безпеки та збереження вантажу, розглядаємо можливості оптимізації ціни транспортування залежно від умов. Співпрацюємо з  автотранспортними  компаніями з пошуку ефективних рішень для наших клієнтів. Безпечний транзит негабаритних вантажів через країни Європи та Азії вимагає вмілого поєднання багатьох складових, які слід пильно відстежувати.


ІСТ-Захід — ваш надійний партнер для транспортування важкої будівельної техніки та матеріалів, верстатів та виробничого обладнання, архітектурних споруд та модульних будинків, великогабаритних місткостей, сільськогосподарської техніки, річкового транспорту та інших великогабаритних вантажів. Знаємо як перевезти мінеральні добрива, вогненебезпечні та вибухові речовини, зріджені гази. 

Наш досвід в організації безпечних перевезень вантажів будь-якої складності до ваших послуг. Організовуємо транспортування країнами Європи та Азії. Знаходимо найефективніші рішення для кожного клієнта. 
`,
    stages: [
      {
        title: `Аналіз`,
        desc: `Докладне вивчення маршруту проходження автотранспорту
        `,
      },
      {
        title: `Експертиза`,
        desc: `Проводимо експертизу транспортно-експедиційних маршрутів
        `,
      },
      {
        title: `Дозволи`,
        desc: `Отримання відповідних спеціальних дозволів
        `,
      },
      {
        title: `Супровід`,
        desc: `Організовуємо супровід та охорону вантажів під час транспортування на вимогу клієнтів

        `,
      },
    ],
  },
  {
    id: 4,
    link: "/collective-cargo",
    title: `ОРГАНІЗАЦІЯ \n ПЕРЕВЕЗЕНЬ \n ЗБІРНИХ \n ВАНТАЖІВ`,
    img: collectiveImg,
    desc: `ІСТ-Захід забезпечує ефективну організацію перевезень збірних вантажів, забезпечуючи оптимальне використання транспортних ресурсів та економію витрат. Наша система логістики гарантує своєчасну доставку вашого вантажу.`,
    info: `

    ІСТ-Захід — ефективна організація перевезень збірних вантажів. Вміємо оптимізувати транспортні ресурси. Створюємо логістичну систему для вчасної доставки ваших речей та вантажів.
    
    Доставка збірних вантажів «від дверей до дверей» – одне з найскладніших транспортних завдань. Управління цим процесом схоже на управління оркестром: тут не обійтися без досвідченого диригента. Вчасно прийняти вантаж у відправника з Великобританії, Франції або Фінляндії, швидко доставити його до термінала в Києві, передати одержувачеві в будь-якій точці України, — без знань та досвіду тут не впоратися. Відмінності вимог митного законодавства різних країн, правила транзитних перевезень, необхідність перевалювання вантажу на терміналах, — все це слід узгодити та проконтролювати.
    
    ІСТ-Захід — надійна ланка цього складного логістичного шляху. Наш досвід та мережа зв’язків є запорукою швидкої та надійної доставки ваших вантажів.
    
    Наша злагоджена робота та досвід — ваша впевненість в надійній та своєчасній доставці ваших збірних вантажів. Адже в нас є всі необхідні інструменти для розв’язання цієї логістичної задачі: регулярні відправки автопоїздів з країн Західної Європи, налагоджені процеси взаємодії з партнерами та відправниками, досвід проходження кордонів та митниць, власний митний термінал у Києві, компетентні працівники відділу збірних вантажів, довгострокові контракти з найкращими перевізниками. Найкоротші терміни доставки та повне збереження вантажів — головні принципи роботи ІСТ-Захід.
    
    При потребі організовуємо доставку вантажів за вашою адресою, розпакування та монтаж меблів, знаходимо спеціалістів для підключення техніки та обладнання. Для термінових і важливих вантажів розробили спеціальний сервіс — експрес-доставка по Україні впродовж 24 годин. Створюємо сервіси під потреби кожного клієнта.
    
    З часу відкриття першого офісу ми обробили тисячі вантажів з різних країн світу. Вдосконалення сервісу та використання новітніх транспортних технологій — наш пріоритет. З 2006 року відкрили великий складський комплекс у Львові: з вигідним розташуванням, зручною логістичною системою, сучасним обладнанням та цілодобовою охороною. Поступово цей склад переріс в оптимальне рішення для регіональної дистрибуції, а зараз відіграє важливу роль на рівні логістики вантажів з Європи в Україну.  
    
    Обирайте ІСТ-Захід для ефективної організації збірних вантажів. З нами ви впевнені в результаті на 100%.
    
    `,
    stages: [
      {
        title: `Офіси`,
        desc: `Офіси корпорації ІСТ в Німеччині, Великобританії та США забезпечують швидку та регулярну відправку  вантажів
        `,
      },
      {
        title: `Інтернаціональність`,
        desc: `Агенти компанії в усіх країнах Європи гарантують безперервність процесу перевезення
        `,
      },
      {
        title: `Оперативність`,
        desc: `Партнери на кордонах забезпечують оперативне проходження митних процедур
        `,
      },
      {
        title: `Досвідченість`,
        desc: `Досвідчені перевізники швидко та безпечно транспортують вантажі автошляхами Європи та України до термінала у Києві

        `,
      },
      {
        title: `Швидкість`,
         desc: `Менеджери організовують швидку обробку вантажів`,
      },
    ],
  },
  {
    id: 5,
    link: "/container-cargo",
    title: `ПЕРЕВЕЗЕННЯ \n МОРСЬКИМИ \n КОНТЕЙНЕРАМИ`,
    img: containerImg,
    desc: `Ми пропонуємо послуги перевезення морськими контейнерами з точністю та пунктуальністю. Наша компанія забезпечує правильну організацію та безпеку при перевезенні вантажів у контейнерах, забезпечуючи їх непошкодженість та надійність доставки.`,
    info: `
    Доставка вантажів в контейнерах — надійний та безпечний спосіб.
    
    Головні принципи організації перевезення вантажів морськими контейнерами — точність та пунктуальність. Для якісного транспортування й доставки неушкоджених вантажів у встановлені терміни потрібен досвід та пильність до кожної дрібниці. Навіть наймасштабніші процеси складаються з багатьох деталей, які потребують уваги.
    
    Перевезення морськими контейнерами починається з підготовки вантажу до завантаження. Вибраний контейнер доставляють на склад або до виробника, де вантаж ретельно пакують та розміщують всередині. Контейнери різних розмірів і типів дозволяють ефективно використовувати простір і забезпечують безпеку вантажу під час транспортування. Після завантаження контейнер герметично закривають та опломбовують, щоб уникнути втрат чи пошкоджень. Контейнер перевантажують на вантажівку, потяг або судно, залежно від маршруту. Завдяки універсальності контейнерів, процес перевантаження з одного виду транспорту на інший відбувається швидко і без зайвих затримок.
    
    По прибуттю до місця призначення контейнер розвантажують на спеціальних терміналах, обладнаних для роботи з великими вантажами. У випадку міжнародних перевезень, вантаж проходить митні процедури, після чого контейнер готовий до подальшої доставки. Залежно від умов договору, вантаж може бути доставлений безпосередньо до кінцевого отримувача або зберігатися на проміжних складах. Після розвантаження контейнер ретельно перевіряють на цілісність, а вантаж — на відповідність замовленню. Завдяки стандартизованим розмірам і конструкції контейнерів, цей процес значно спрощується і прискорюється, що дозволяє підприємствам ефективніше керувати своїми логістичними операціями.
    
    У сучасному світі логістики перевезення морськими контейнерами є ефективним і зручним способом транспортування, що економить час, гроші та забезпечує безпеку і надійність вантажів будь-якого типу. Переваг таких перевезень для бізнесу одразу декілька.

    Перевезення морськими контейнерами — вигідне та ефективне рішення для багатьох бізнесів, які шукають спосіб оптимізувати свою логістику та забезпечити безперебійну поставку вантажів. Зверніться до наших фахівців, щоб отримати додаткову інформацію та поради щодо використання контейнерних перевезень для вашого бізнесу.
    `,
    stages: [
      {
        title: `Гнучкість та масштабованість процесу`,
        desc: `Перевезення морськими контейнерами легко масштабуються відповідно до обсягу вантажів. Ви можете вибирати контейнери різних розмірів і типів залежно від потреб, що забезпечує гнучкість і спрощує логістику вашого бізнесу.
        `,
      },
      {
        title: `Ефективність та швидкість перевезень`,
        desc: `Контейнерні перевезення швидкі та ефективні, оскільки контейнери легко завантажуються на судна, потяги або автотранспорт, і доставляються безпосередньо до пункту призначення. Це скорочує час доставки та оптимізує ланцюг постачання.
        `,
      },
      {
        title: `Зменшення ризиків та оптимізація витрат`,
        desc: `Використання контейнерів спрощує обробку та перевезення вантажів, зменшуючи ризики втрат і пошкоджень. Крім того, це економить витрати на упаковку та забезпечує високий рівень захисту вантажу.
        `,
      },
      // {
      //   title: `Глобальний доступ до міжнародних ринків`,
      //   desc: `Контейнерні перевезення легко організовують міжнародні перевезення вантажів, охоплюючи багато регіонів. Це ідеальний вибір для бізнесів, які займаються міжнародною торгівлею та потребують надійної доставки вантажів.
      //   `,
      // },
    ],
  },
  {
    id: 6,
    link: "/container-storage",
    external_link:'https://box24.lviv.ua/',
    title: `ОРЕНДА\n КОНТЕЙНЕРІВ\n ДЛЯ\n ІНДИВІДУАЛЬНОГО\nЗБЕРІГАННЯ`,
    img: containerStorageImg,
    desc: `Надання послуг тимчасового або довготривалого зберігання майна.`,
    info: `
   
Оренда міні-складу  – сучасна послуга, що набирає популярності. Саме для тих, кого цікавить оренда контейнера у Львові, ми створили свою пропозицію. Вам потрібно зберігати більше речей, але у вашому будинку чи квартирі немає місця для них? Приходьте до нас!  Ми пропонуємо три розміри контейнерів, серед яких ви точно знайдете ідеальний. Пам’ятаючи про зручність клієнтів, склади розміщені таким чином, щоб забезпечити прямий доступ автомобілем до них. Оренда складу – контейнера від ІСТ-Захід – це приміщення для тимчасового зберігання меблів, інструментів, обладнання для дому та офісу, автомобільних шин, іграшок, сувенірів та всіх тих предметів, які більше не вміщуються в домашніх підвалах, гаражах та на горищах. 

Переваги оренди міні-складу у Львові:

    - Можливість ідеального підбору розміру. Ми пропонуємо контейнери в оренду площею 7, 14 та 29 м2. Можливість правильно вибрати простір означає, що ми зможемо ідеально використовувати його для зберігання певної кількості речей. У разі збільшення потреб завжди можна взяти в оренду більший контейнер. 
    
    - Оренда складу для компаній та приватних осіб. Оренда контейнера  від підприємства ІСТ-Захід – послуга для компаній, а також приватних осіб. Склади знаходяться під постійним захистом, тому ви можете бути впевнені, що ваше майно у безпеці. Навіть, якщо це важливі документи чи матеріальні цінності. 
   
    - Доступ цілодобово. Наші склади самообслуговування працюють цілодобово, 7 днів на тиждень. Ви можете будь-коли прийти до орендованого контейнера, щоб забрати або принести свої речі. Вночі є зовнішнє освітлення. 
   
    - Зручне користування. Наші склади відрізняються простотою експлуатації. Оренда контейнера пропонується на території, яка має простору автостоянку і гарантує безпечне зберігання ваших вантажів, обладнання та інших предметів. Все підготовлено таким чином, щоб забезпечити найзручніший доступ до предметів, що зберігаються в наших контейнерах. Завдяки нашій пропозиції, незалежно від часу доби і ночі, ви можете отримати доступ до кожного збереженого предмета. Пропонується послуга автонавантажувача. 
   
    - Безпека понад усе. Напевно, вам цікаво, чи ваші речі будуть у нас у безпеці. Ми можемо запевнити вас, що ми доклали всіх зусиль, щоб ваш велосипед, інструменти чи меблі були найкраще захищені у нас. Наші контейнери для самообслуговування мають міцний замок. В інтересах безпеки довірених нам предметів весь об’єкт охоплюється цілодобовим моніторингом. 

Чому саме ІСТ-Захід?

Ми гнучкі і зосереджені на комфорті, який проявляється серед іншого, у тому, що ви можете орендувати наші склади та контейнери самообслуговування на будь-який період часу. Це може бути шість місяців, може три місяці, а може і рік, і більше.  Мінімальний термін зберігання – одна доба.
    `,
    stages: [
      {
        title: "Безпека",
        desc: "Склади знаходяться під постійним наглядом та охороною, тому ви можете зберігати тут навіть важливі документи та цінні речі"

      },
      {
        title: "Цілодобовий доступ",
        desc: "Склади самообслуговування працюють в режимі 24/7, тобто ви можете покласти або забрати речі у зручний для вас час."
        ,
      },
      {
        title: "Зручність",
        desc: "Територія освітлюється, достатньо місця для паркування, зручна транспортна розв’язка, — все для вашого комфорту. Додатково пропонується послуга автонавантажувача"
    
      },

    ],
  },
];
