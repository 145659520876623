import ukrainePhoto from '../assets/images/services/ukraine.jpg'
import internationalPhoto from '../assets/images/services/international.jpg'
import adrPhoto from '../assets/images/services/adr.jpg'
import zbirPhoto from '../assets/images/services/zbir.jpg'
import containerPhoto from '../assets/images/services/container.jpg'
import orendaPhoto from '../assets/images/services/orenda.jpg'



export const services_data = [
    {title:`Перевезення вантажів по території України`,
    text:`ІСТ-Захід — провідна компанія з перевезення вантажів по Україні. Пропонуємо ефективні та індивідуальні рішення для безпечного доставлення вантажу. Незалежно від обсягу чи типу вантажу — гарантуємо надійність та швидкість транспортування.`,img:ukrainePhoto,link:'/ukraine-cargo'},
    {title:`Міжнародні вантажні перевезення`,text:`ІСТ-Захід — надійний партнер у міжнародних вантажних перевезеннях. Пропонуємо найефективніші рішення для транспортування вантажів по всьому світу. Знаємо, як швидко та безпечно доставити ваш вантаж за вказаною адресою.`,img:internationalPhoto,link:'/international-cargo'},
    {title:`Перевезення негабаритних та небезпечних (ADR) Вантажів`,text:`ІСТ-Захід — багаторічний досвід у перевезенні негабаритних та небезпечних вантажів відповідно до стандартів ADR. Дотримуємося усіх вимог безпеки та норм законодавства. Знаємо, як впоратися із найскладнішими завданнями у сфері логістики.`,img:adrPhoto,link:'/adr-cargo'},
    {title:`Організація перевезень збірних вантажів`,text:`ІСТ-Захід — ефективна організація перевезень збірних вантажів. Вміємо оптимізувати транспортні ресурси та розумно заощадити ваші витрати. Створюємо логістичну систему для вчасної доставки ваших речей та вантажів. `,img:zbirPhoto,link:'/collective-cargo'},
    {title:`Перевезення морськими контейнерами`,text:`ІСТ-Захід — безпека та надійність доставки вантажів морськими контейнерами. Організовуємо та контролюємо процес перевезення, щоб ваші вантажі прибували вчасно та неушкодженими.`,img:containerPhoto,link:'/container-cargo'},
    {title:`Оренда контейнерів`,text:`ІСТ-Захід — надійне зберігання ваших речей та вантажів в контейнерах. Організовуємо як тимчасове, так і довготривале зберігання. Пропонуємо гнучкі умови оренди контейнерів під склад у Львові.`,img:orendaPhoto,link:'/container-storage'},
]