export const owner__data = [
    {id:1,text:`Звернення замовника до нас,для отримання певної послуги,або ж звернення наше до замовника з пропозицією співпраці`},
    {id:2,text:`Проведення переговорів, отримання технічного завдання від замовника`},
    {id:3,text:`Прорахунок вартості послуги, яка цікавить замовника, термінів виконання, додаткових умов (або участь в тендері, якщо є така умова)`},
    {id:4,text:`Погодження з замовником всіх нюансів замовлення`},
    {id:5,text:`Обмін установчими документами, ознайомлення з драфтом договору, опрацювання (перевірка)`},
    {id:6,text:`Підписання договору, підписання заявки`},
    {id:7,text:`Виконання замовлення`},
    {id:8,text:`Оформлення та виставлення документів та рахунку замовнику`},
    {id:9,text:`Оплата`},
]