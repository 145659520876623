export const adventages__data = [
  {
    title: `Надійність`,
    text: `Працюємо з партнерами, які найкращі у своїй ніші ринку. Впевнені в підрядниках, з якими співпрацюємо роками.`,
  },
  {
    title: `Оперативність`,
    // text: `Наші менеджери вміють швидко знайти транспорт, розробити оптимальний маршрут, визначити терміни перевезень та дати чіткі вказівки водію.`,
    text: `Наші менеджери вміють швидко розробити оптимальний маршрут, визначити терміни доставки вантажу та надати транспорт.`,
  },
  {
    title: `Безпека`,
    text: `Гарантуємо застраховану відповідальність експедитора, сучасні системи моніторингу та високі стандарти  перевезень вантажів.`,
  },
  {
    title: `Систематизованість`,
    text: `Маємо напрацьовану роками базу даних та надійне програмне забезпечення для ефективного транспортування ваших вантажів.
    `,
  },
  {
    title: `Досвід`,
    text: `Понад 25 років досвіду та хороша репутація на ринку міжнародних перевезень додають нам впевненості навіть у складні часи.
    `,
  },
  {
    title: `Команда`,
    text: `Запрошуємо до себе найкращих у сфері логістики, дбаємо про розвиток та професійне зростання персоналу.
    `,
  },
];
