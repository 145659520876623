import React, { useEffect } from "react";
import "./AboutUsPage.scss";
import AboutUs from "../../sections/about/AboutUs";

import Values from "../../sections/values/Values";
import OurAdvantages from "../../sections/advantages/OurAdvantages";
import FreeConsultation from "../../components/free-consultation/FreeConsultation";
import axios from "axios";
import useRecordVisit from "../../hooks/useRecordVisit";

const AboutUsPage = () => {
  const data = [
    { data: "25+", title: "Років досвіду" },
    { data: "3000+", title: "Перевезень в місяць" },
    { data: "400+", title: "Задоволених клієнтів" },
    { data: "40k+", title: "Перевезень в рік" },
  ];
useRecordVisit('Про нас')
const cities = [
  'Київ',
  "Львів", 
  "Вінниця", 
  "Дніпро", 
  "Івано-Франківськ", 
  "Луцьк", 
  "Мукачево", 
  "Рівне", 
  "Суми", 
  "Тернопіль", 
  "Харків", 
  "Черкаси"
];
  return (
    <>
      <div className="about__us-page page">
        <AboutUs />
        <div className="about_us-text container text-justify">
          <p>
            Сьогодні ІСТ-Захід — це логістична компанія з розгалуженою мережею
            регіональних підрозділів на заході України. Наші офіси працюють у таких містах: <br /><br />
            Київ, Львів, Вінниця, Дніпро, Івано-Франківськ, Луцьк, Мукачево, Рівне, Суми, Тернопіль, Харків та Черкаси.


{/* 
            {cities.map((item,idx)=>{
      return     <span  key={idx}>{item}{idx < cities.length - 1 ? ',' : '.'}</span >
    })} */}
          

            
          </p>
          <br />
          <p>
            Перевага наших послуг у тому, що клієнт замовляє всі види перевезень
            в рамках однієї компанії: ви спілкуєтеся з одним менеджером і не
            витрачаєте час на домовленості та узгодження між різними
            перевізниками. Ми організовуємо перевезення вантажів різного
            розміру, об’єму та складності. Працюємо з усіма видами перевезень:
            автомобільними, морськими, річковими, залізничними, авіаційними.
            Знаємо, як доставити ваш вантаж з однієї точки світу в іншу.
          </p>

          <br />

          <h3>
            За роки нам вдалося напрацювати широкий спектр експедиторських та
            транспортних послуг:
          </h3>

          <ul>
            <li>
              автомобільні перевезення всіх видів вантажів територією України;
             
            </li>
            <li>автомобільні перевезення країнами Європи та Азії;</li>
            <li>організація транспортування негабаритних вантажів;</li>
            <li>
              організація перевезень небезпечних (ADR) вантажів;
            </li>
            <li>організація перевезень збірних вантажів;</li>
            <li>організація контейнерних перевезень;</li>

            <li>організація залізничних та авіа перевезень;</li>
            <li>організація річкових та морських перевезень;</li>
            <li>консультації з будь-яких транспортних питань;</li>
            <li>оренда контейнерів для зберігання речей.</li>
          </ul>

          <br />
         <br />
          <p>
            Якою б не була відстань, ІСТ-Захід — найближча до вас компанія,
            готова взяти на себе всі клопоти з доставкою вантажу. Надаємо
            послуги як бізнесу, так і фізичним особам. Доставка вантжів з Європи
            до мережі ваших магазинів, перевезення негабаритної будівельної чи
            сільськогосподарської техніки. Надійна
            компанія з організації перевезень одна на всі випадки життя —
            ІСТ-Захід.
          </p>
          <br />
          <p>
            Логістика — це насамперед вміння організувати процес. В успіху
            компанії ІСТ-Захід ключову роль відіграє команда. Кожен наш менеджер
            має високу кваліфікацію та багаторічний досвід у сфері транспортних
            перевезень. Спільна робота команди забезпечує безперебійну
            координацію всіх етапів перевезень — від планування маршрутів до
            доставки вантажів. Злагоджена робота менеджерів є запорукою
            оперативності, надійності та безпеки кожного перевезення. Інвестиції
            у розвиток та навчання персоналу, турбота про наших працівників та
            підтримки — невіддільна частина корпоративної культури ІСТ-Захід.
          </p>
          <br />
          <p>
            Цінності компанії базуються на потребах клієнта. Завжди шукаємо
            можливості доставки вантажів під ваші запити. Високі стандарти
            обслуговування — це легка комунікація, ефективне рішення, надійне та
            безпечне перевезення. Прагнемо до постійного вдосконалення та
            використовуємо інновації, щоб вчасно реагувати на нові виклики у
            сфері транспортування вантажів.
          </p>
          <br />
          <p>
            Соціальна відповідальність ІСТ-Захід — це турбота про довкілля через
            дотримання норм та правил перевезення важтажів. Ми також піклуємося
            про спільноти, до яких дотичні в процесі роботи, та відкриті до
            благодійних проєктів. Вдячні Збройним Силам України за можливість
            працювати, долучаємося фінансово до потреб військових підрозділів
            щомісячною підтримкою.
          </p>
          <br />
          <p>
            Якщо шукаєте надійну логістичну компанію — звертайтесь до ІСТ-Захід.
            Ми знаємо про транспортні перевезення все.
          </p>
          <br />
        </div>
        <div className="about__us-page-inner container">
          <div className="statistic__panel">
            <div data-aos="zoom-in" className="statistic__panel-inner">
              {data.map((item, idx) => {
                return (
                  <div key={idx} className="service__item">
                    <span className="header__text">{item.data}</span>
                    <span className="title__text">{item.title}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <Values />
      </div>
      <OurAdvantages />
      <FreeConsultation />
    </>
  );
};

export default AboutUsPage;
